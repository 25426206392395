import IconSearch from '../assets/icon_search.svg'
import useTranslation from 'next-translate/useTranslation'
import Link from 'next/link'

export function SearchBarButton() {
  const { t } = useTranslation()

  return (
    <Link
      href="/search"
      prefetch
      className="relative flex items-stretch w-[300px]"
    >
      <div className="flex-grow flex items-center border border-r-0 border-gray-300 rounded-l-md bg-white">
        <div className="w-full py-2 px-4 text-xs text-gray-400 cursor-text">
          {t('common:header.find_your_item_short')}
        </div>
      </div>
      <div className="flex items-center justify-center w-11 h-11 bg-black rounded-r-md">
        <IconSearch className="w-6 h-6 text-white" />
      </div>
    </Link>
  )
}
