import IconSearch from '../assets/icon_search.svg'
import Link from 'next/link'

export function SearchButton() {
  return (
    <Link href="/search" prefetch>
      <div className="size-10 text-black flex items-center justify-center">
        <IconSearch width={20} height={20} />
      </div>
    </Link>
  )
}
