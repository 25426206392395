
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __appWithI18n from 'next-translate/appWithI18n'
// @ts-ignore
    
import '@formatjs/intl-locale/polyfill'
import '@formatjs/intl-pluralrules/locale-data/de'
import '@formatjs/intl-pluralrules/locale-data/en'
import '@formatjs/intl-pluralrules/polyfill'
import { DehydratedState } from '@tanstack/react-query'
import dayjs from 'dayjs'
import Cookies from 'js-cookie'
import Head from 'next/head'
import { Router, useRouter } from 'next/router'
import { SnackbarProvider } from 'notistack'
import { useEffect, useState } from 'react'
import { RecoilRoot, useRecoilTransactionObserver_UNSTABLE } from 'recoil'
import 'src/style/components/GlobalStyle/fonts.css'
import 'src/style/components/GlobalStyle/tw.css'
import 'src/style/external/slick.css'
import { customerState } from '@/app/auth/auth.state'
import CustomerProvider from '@/app/auth/components/CustomerProvider'
import OnlinePaymentSubscription from '@/app/auth/components/OnlinePaymentSubscription/CustomerSubscription'
import {
  availableRegionsState,
  redirectToCorrectDomainState,
} from '@/app/common/common.state'
import { ClientOnly } from '@/app/common/components/ClientOnly'
import { ModalDialogProvider } from '@/app/common/components/ModalDialog'
import PageProvider from '@/app/common/components/PageProvider/PageProvider'
import { PreviewMode } from '@/app/common/components/PreviewMode'
import StoryblokConfigProvider from '@/app/common/context/storyblokConfigContext'
import { ActiveExperimentsProvider } from '@/app/common/experiments/context'
import { GraphQLCLientProvider } from '@/app/common/graphql/context'
import { ReactQueryProvider } from '@/app/common/react-query/ReactQueryProvider'
import { persistState, restoreClientState } from '@/app/common/recoil'
import { initStoryblok } from '@/app/common/storyblok'
import {
  checkoutDateState,
  customDealAvailableTransportModesState,
  customDealIdState,
  dealBookingNumberState,
  dealCalculationState,
  dealDurationInDaysState,
  dealIdState,
  dealTypeState,
  feedbackSentenceState,
  feedbackState,
  guestEmailState,
  isCustomDealPayoutOverwrittenState,
  isSimplifiedPawnCheckoutState,
  knpDebtState,
  paymentDataState,
  selectedItemsState,
  transportDataState,
  transportFormStateCheckout,
} from '@/domains/checkout/checkout.state'
import { CONSENT_MODE_LOCAL_STORAGE_KEY } from '@/domains/cookies/CookiesAgreement/CookiesModal'
import {
  dealItemsState,
  extensionPayoutTypeState,
  paybackPayoutTypeState,
  paybackShopIdState,
  paybackTransportDataState,
  successSentenceState,
  transportFormStateProfile,
} from '@/domains/profile/profile.state'
import tracking from '@/helpers/tracking'
import { RegionCodeContextProvider } from '@/helpers/useRegionCode'
import { ThemeWrapper } from '@/style'
import { ERegionCode } from '@/types/gql/graphql'
import { ConfigStoryblok } from '@/types/storyblok-component-types'

initStoryblok()

const persistedAtoms = [
  dealDurationInDaysState,
  customDealIdState,
  selectedItemsState,
  checkoutDateState,
  dealCalculationState,
  transportDataState,
  paymentDataState,
  dealIdState,
  feedbackState,
  customerState,
  dealTypeState,
  feedbackSentenceState,
  paymentDataState,
  dealItemsState,
  paybackTransportDataState,
  paybackPayoutTypeState,
  paybackShopIdState,
  extensionPayoutTypeState,
  transportFormStateCheckout,
  transportFormStateProfile,
  successSentenceState,
  knpDebtState,
  redirectToCorrectDomainState,
  availableRegionsState,
  customDealAvailableTransportModesState,
  isCustomDealPayoutOverwrittenState,
  dealBookingNumberState,
  isSimplifiedPawnCheckoutState,
  guestEmailState,
]

function PersistenceObserver() {
  useRecoilTransactionObserver_UNSTABLE(({ snapshot }) => {
    persistState(snapshot, persistedAtoms)
  })

  return null
}

interface PageProps {
  regionCode: ERegionCode
  config: ConfigStoryblok
  dehydratedState: DehydratedState
  preview?: boolean
  activeExperiments?: string[]
}

function CashyApp({
  Component,
  pageProps,
}: {
  Component: any
  pageProps: PageProps
}) {
  const { locale } = useRouter()
  const [prevLocale, setPrevLocale] = useState<string | undefined>()
  if (locale !== prevLocale) {
    setPrevLocale(locale)
    dayjs.locale(locale)
  }

  useEffect(() => {
    setGiromatchCookieIfApplicable(document.referrer)

    const jssStyles = document.querySelector('#jss-server-side')

    if (jssStyles && jssStyles.parentNode)
      jssStyles.parentNode.removeChild(jssStyles)

    const handleRouteChange = (url: string) => {
      tracking.pageView({ vurl: url })
      tracking.tryPushDataLayer({
        expId: 'lI7xBTU4TBGk0XGxrFpoAg',
        expVar: '0',
      })
    }
    Router.events.on('routeChangeComplete', handleRouteChange)
    return () => {
      Router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [])

  return (
    <>
      <ClientOnly>
        {(() => {
          return null
        })()}

        <Head>
          <script
            dangerouslySetInnerHTML={{
              __html: `
              (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
              new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
              j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
              '${tracking.GTM_TAGGING_SERVER}/gtm.js?id='+i+dl+'${tracking.GTM_EXTRA_PARAMS}';f.parentNode.insertBefore(j,f);
              })(window,document,'script','dataLayer','${tracking.GTM_TRACKING_ID}');

              (function() {
                window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);};
                window.gtag=gtag;

                let consent = typeof localStorage !== 'undefined' &&
                  localStorage.getItem('${CONSENT_MODE_LOCAL_STORAGE_KEY}')
                if (consent) {
                  try {
                    consent = JSON.parse(consent)
                  } catch (error) {
                    console.error('Failed to parse consent from local storage.', consent, error)
                    localStorage.removeItem('${CONSENT_MODE_LOCAL_STORAGE_KEY}')
                    consent = null
                  }
                }

                if (typeof consent !== 'object') {
                  consent = null
                } else if (consent && !consent.tiktok_pixel) {
                  if (typeof localStorage !== 'undefined') {
                    localStorage.removeItem('${CONSENT_MODE_LOCAL_STORAGE_KEY}')
                  }

                  consent = null
                } else if (consent && !consent.ad_user_data) {
                  consent.ad_user_data = consent.ad_personalization = consent.ad_storage;
                  localStorage.setItem('${CONSENT_MODE_LOCAL_STORAGE_KEY}', JSON.stringify(consent))
                }

                gtag('consent', 'default', consent || {
                  'ad_storage': 'denied',
                  'ad_user_data': 'denied',
                  'ad_personalization': 'denied',
                  'analytics_storage': 'denied',
                  'personalization_storage': 'granted',
                  'functionality_storage': 'granted',
                  'ads_data_redaction': true,
                  'security_storage': 'granted',
                  'meta_pixel': 'denied',
                  'microsoft_ads': 'denied',
                  'tiktok_pixel': 'denied',
                })
            })()`,
            }}
          />
        </Head>
      </ClientOnly>
      <ThemeWrapper>
        <SnackbarProvider
          anchorOrigin={{
            horizontal: 'center',
            vertical: 'bottom',
          }}
          maxSnack={1}
          hideIconVariant={true}
        >
          <ReactQueryProvider dehydratedState={pageProps.dehydratedState}>
            <GraphQLCLientProvider>
              <RecoilRoot initializeState={restoreClientState(persistedAtoms)}>
                <PersistenceObserver />
                <CustomerProvider />
                <ClientOnly>
                  <OnlinePaymentSubscription />
                </ClientOnly>
                <Head>
                  <meta
                    name="viewport"
                    content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
                  />
                </Head>
                <PreviewMode preview={pageProps.preview}>
                  <RegionCodeContextProvider regionCode={pageProps.regionCode}>
                    <StoryblokConfigProvider config={pageProps.config}>
                      <ActiveExperimentsProvider
                        activeExperiments={pageProps.activeExperiments}
                      >
                        <ModalDialogProvider>
                          <PageProvider>
                            <Component {...pageProps} />
                          </PageProvider>
                        </ModalDialogProvider>
                      </ActiveExperimentsProvider>
                    </StoryblokConfigProvider>
                  </RegionCodeContextProvider>
                </PreviewMode>
              </RecoilRoot>
            </GraphQLCLientProvider>
          </ReactQueryProvider>
        </SnackbarProvider>
      </ThemeWrapper>
    </>
  )
}

const __Page_Next_Translate__ = CashyApp

function setGiromatchCookieIfApplicable(referer?: string) {
  try {
    if (!referer) return

    const DOMAIN =
      typeof window !== 'undefined' ? window.location.hostname : undefined

    const refererUrl = new URL(referer)
    if (refererUrl.host === 'www.giromatch.com') {
      Cookies.set('referrer_giromatch', '1', { domain: DOMAIN, expires: 14 })
    }
  } catch {}
}


// @ts-ignore
    export default __appWithI18n(__Page_Next_Translate__, {
// @ts-ignore
      ...__i18nConfig,
// @ts-ignore
      isLoader: true,
// @ts-ignore
      skipInitialProps: true,
// @ts-ignore
      
// @ts-ignore
    });
// @ts-ignore
  