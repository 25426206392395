import { debounce, sumBy } from 'lodash'
import phone from 'phone'
import config from '@/config'
import { SelectedProduct } from '@/types'
import { ProductLegacy } from '@/types/gql/graphql'

const GTM_TRACKING_ID = process.env.GTM_TRACKING_ID
const GTM_TAGGING_SERVER =
  process.env.GTM_TAGGING_SERVER || 'https://www.googletagmanager.com'
const GTM_EXTRA_PARAMS = process.env.GTM_EXTRA_PARAMS ?? ''

type PageViewInput = {
  event: 'pageview'
  pagecategory: string
  pagetitle: string
  vurl?: string
}

const tryPushDataLayer = (input: any) => {
  let enableLocalTracking = false
  const urlParams = new URLSearchParams(window.location.search)
  enableLocalTracking = !!urlParams.get('enableLocalTracking')

  const { env } = config
  if (
    typeof (global as any).dataLayer !== 'undefined' &&
    (env === 'production' ||
      env === 'staging' ||
      env === 'release-candidate' ||
      enableLocalTracking)
  ) {
    const dataLayer = (global as any).dataLayer

    if ('ecommerce' in input) {
      dataLayer.push({ ecommerce: null })
    }
    dataLayer.push(input)
  }
}

const pageView = (input: Partial<PageViewInput>) => {
  tryPushDataLayer({
    event: 'pageview',
    pagetitle: document.title,
    pagecategory: 'content',
    ...input,
  })
}

interface GA4Event<
  TEvent extends string,
  TPayload extends Record<string, any>,
> {
  event: TEvent
  ecommerce: TPayload
}

type GA4Currency = 'EUR' | 'USD'
interface GA4AddToCartPayload {
  currency: GA4Currency
  value: number
  items: GA4Item[]
}

function addToCartLegacy(products: TrackingProduct[]) {
  const items = products.map(ga4ItemFromProduct)
  const event: GA4Event<'add_to_cart', GA4AddToCartPayload> = {
    event: 'add_to_cart',
    ecommerce: {
      currency: 'EUR',
      value: sumBy(items, (i) => i.price ?? 0),
      items,
    },
  }

  tryPushDataLayer(event)
}

function addToCart(products: NewTrackingProduct[]) {
  const items = products.map(ga4ItemFromNewProduct)
  const event: GA4Event<'add_to_cart', GA4AddToCartPayload> = {
    event: 'add_to_cart',
    ecommerce: {
      currency: 'EUR',
      value: sumBy(items, (i) => i.price ?? 0),
      items,
    },
  }

  tryPushDataLayer(event)
}

type TrackingEvent =
  | { event: 'login' }
  | { event: 'registration' }
  | { event: 'custom_deal_request'; value: number; type: TrackingPurchaseType }
  | { event: 'page_not_found' }
  | { event: 'request_ringback' }
  | { event: 'open_navigation' }
  | { event: 'open_search' }
  | { event: 'cta_click'; id: string }
  | { event: 'section_start_visible'; id: string }
  | { event: 'section_end_visible'; id: string }
  | {
      event: 'search'
      'search-source': string
      'search-term': string
    }
  | {
      event: 'search_stopped_typing'
      'search-source': string
      'search-term': string
      'search-count': number
    }

function trackEvent<T extends TrackingEvent['event']>(
  event: T,
  ...args: Exclude<
    keyof Extract<TrackingEvent, { event: T }>,
    'event'
  > extends never
    ? [undefined?]
    : [Omit<Extract<TrackingEvent, { event: T }>, 'event'>]
) {
  tryPushDataLayer({ event, ...args[0] })
}

/** Handles tracking the `search` as well as `search_stopped_typing` events. */
function trackSearch(
  data: Omit<
    Extract<TrackingEvent, { event: 'search_stopped_typing' }>,
    'event'
  >,
) {
  trackEvent('search', data)
  debouncedSearchStoppedTyping(data)
}

const debouncedSearchStoppedTyping = debounce(
  (
    data: Omit<
      Extract<TrackingEvent, { event: 'search_stopped_typing' }>,
      'event'
    >,
  ) => data['search-term'] && trackEvent('search_stopped_typing', data),
  1_000,
)

type UserInput = RegisteredUserInput | GuestUserInput

type RegisteredUserInput = {
  id: string
  email: string
  phone: string | null
  address?: {
    firstname: string
    lastname: string
    zip: string
    country: string
    street: string
  }
}

type GuestUserInput = {
  email: string | null
}

function user(input: UserInput | null) {
  if (input && 'phone' in input && input?.phone) {
    // Must be in E.164 format// Must be in E.164 format
    const phoneResult = phone(input.phone)
    if (phoneResult.isValid) {
      input.phone = phoneResult.phoneNumber
    } else {
      input.phone = null
    }
  }
  tryPushDataLayer({
    user: input,
  })
}

interface GA4ViewItemPayload {
  currency: GA4Currency
  value: number
  items: GA4Item[]
}

function viewItemLegacy(products: TrackingProduct[]) {
  const items = products.map(ga4ItemFromProduct)
  const event: GA4Event<'view_item', GA4ViewItemPayload> = {
    event: 'view_item',
    ecommerce: {
      currency: 'EUR',
      value: sumBy(items, (i) => i.price ?? 0),
      items,
    },
  }

  tryPushDataLayer(event)
}

function viewItem(products: NewTrackingProduct[]) {
  const items = products.map(ga4ItemFromNewProduct)
  const event: GA4Event<'view_item', GA4ViewItemPayload> = {
    event: 'view_item',
    ecommerce: {
      currency: 'EUR',
      value: sumBy(items, (i) => i.price ?? 0),
      items,
    },
  }

  tryPushDataLayer(event)
}

interface GA4ViewItemListPayload {
  item_list_id?: string
  item_list_name?: string
  items: GA4Item[]
}

function viewItemList(
  products: TrackingProduct[],
  args: Omit<GA4ViewItemListPayload, 'items'> = {},
) {
  const event: GA4Event<'view_item_list', GA4ViewItemListPayload> = {
    event: 'view_item_list',
    ecommerce: {
      ...args,
      items: products.map(ga4ItemFromProduct),
    },
  }

  tryPushDataLayer(event)
}

interface GA4BeginCheckoutPayload {
  currency: GA4Currency
  value: number
  coupon?: string
  items: GA4Item[]
}

function beginCheckout(products: TrackingProduct[]) {
  const items = products.map(ga4ItemFromProduct)
  const event: GA4Event<'begin_checkout', GA4BeginCheckoutPayload> = {
    event: 'begin_checkout',
    ecommerce: {
      currency: 'EUR',
      value: sumBy(items, (i) => i.price ?? 0),
      items,
    },
  }

  tryPushDataLayer(event)
}

type TrackingShipmentType = 'Shop' | 'Delivery'
interface GA4AddShippingInfoPayload {
  currency: GA4Currency
  value: number
  coupon?: string
  shipping_tier: TrackingShipmentType
  items: GA4Item[]
}

function addShippingInfo(
  products: TrackingProduct[],
  shipmentType: TrackingShipmentType,
) {
  const items = products.map(ga4ItemFromProduct)
  const event: GA4Event<'add_shipping_info', GA4AddShippingInfoPayload> = {
    event: 'add_shipping_info',
    ecommerce: {
      currency: 'EUR',
      value: sumBy(items, (i) => i.price ?? 0),
      shipping_tier: shipmentType,
      items,
    },
  }

  tryPushDataLayer(event)
}

type TrackingPaymentType = 'PayPal' | 'Cash' | 'Bank'
interface GA4AddPaymentInfoPayload {
  currency: GA4Currency
  value: number
  coupon?: string
  payment_type: TrackingPaymentType
  items: GA4Item[]
}

function addPaymentInfo(
  products: TrackingProduct[],
  paymentType: TrackingPaymentType,
) {
  const items = products.map(ga4ItemFromProduct)
  const event: GA4Event<'add_payment_info', GA4AddPaymentInfoPayload> = {
    event: 'add_payment_info',
    ecommerce: {
      currency: 'EUR',
      value: sumBy(items, (i) => i.price ?? 0),
      payment_type: paymentType,
      items,
    },
  }

  tryPushDataLayer(event)
}

type TrackingPurchaseType = 'car' | 'pawn' | 'purchase'
interface GA4PurchasePayload {
  transaction_id: string
  currency: GA4Currency
  value: number
  coupon?: string
  shipping?: number
  tax?: number
  items: GA4Item[]
}

function purchase(
  products: TrackingProduct[],
  {
    type,
    ...args
  }: Omit<GA4PurchasePayload, 'currency' | 'value' | 'items'> & {
    type: TrackingPurchaseType
  },
) {
  const items = products.map(ga4ItemFromProduct)
  const event: GA4Event<'purchase', GA4PurchasePayload> & {
    type: TrackingPurchaseType
  } = {
    event: 'purchase',
    type,
    ecommerce: {
      ...args,
      currency: 'EUR',
      value: sumBy(items, (i) => i.price ?? 0),
      items,
    },
  }

  tryPushDataLayer(event)
}

type B2BRequestEventInput = {
  eventLabel: string
}

const b2bRequestEvent = (input: B2BRequestEventInput) => {
  tryPushDataLayer({
    event: 'microconv',
    event_name: 'microconv',
    event_category: 'microconv',
    event_action: 'B2Bform',
    event_label: input.eventLabel,
  })
}

// see https://developers.google.com/analytics/devguides/collection/ga4/reference/events?client_type=gtag#view_item_list_item
interface GA4Item {
  /**
   * Either item_id or item_name is required.
   */
  item_id?: string
  item_name: string
  affiliation: 'Website'
  coupon?: string
  /**
   * @default 0
   */
  discount?: number
  index?: number
  item_brand?: string
  item_category?: string
  item_category2?: string
  item_category3?: string
  item_category4?: string
  item_category5?: string
  item_list_id?: string
  item_list_name?: string
  item_variant?: string
  location_id?: string
  price?: number
  /**
   * @default 1
   */
  quantity?: number
}

export type TrackingProduct = Pick<
  ProductLegacy,
  'title' | 'contentCategories' | 'maxValue'
> & {
  actualValue?: number
}

type NewTrackingProduct = SelectedProduct & {
  actualValue?: number
}

function ga4ItemFromNewProduct(product: NewTrackingProduct): GA4Item {
  const [lvl0, lvl1, lvl2, lvl3] = product.category.parentCategories

  return {
    item_name: product.name,
    affiliation: 'Website',
    item_brand: product.manufacturer || undefined,
    item_category: lvl0.name,
    item_category2: lvl1?.name || undefined,
    item_category3: lvl2?.name || undefined,
    item_category4: lvl3?.name || undefined,
    price: product.actualValue || undefined,
  }
}

// Product
function ga4ItemFromProduct(product: TrackingProduct): GA4Item {
  return {
    item_name: product.title,
    affiliation: 'Website',
    item_brand: product.contentCategories?.lvl1 || undefined,
    item_category: product.contentCategories?.lvl0,
    item_category2: product.contentCategories?.lvl1 || undefined,
    item_category3: product.contentCategories?.lvl2 || undefined,
    item_category4: product.contentCategories?.lvl3 || undefined,
    price: product.actualValue ?? (product.maxValue || undefined),
  }
}

export default {
  GTM_TAGGING_SERVER,
  GTM_TRACKING_ID,
  GTM_EXTRA_PARAMS,
  pageView,
  tryPushDataLayer,
  addToCart,
  b2bRequestEvent,
  trackEvent,
  trackSearch,
  user,
  viewItem,
  viewItemList,
  beginCheckout,
  addPaymentInfo,
  addShippingInfo,
  purchase,
  viewItemLegacy,
  addToCartLegacy,
}
