import {
  ISbResult,
  ISbStoriesParams,
  ISbStoryData,
  apiPlugin,
  storyblokInit,
} from '@storyblok/react'
import { GetServerSidePropsContextWithLocale } from 'next'
import Storyblok from 'storyblok-js-client'
import AboutPage from '@/blocks/About/About'
import AdvantagesGrid from '@/blocks/AdvantagesGrid/AdvantagesGrid'
import AdvantagesGridItem from '@/blocks/AdvantagesGrid/AdvantagesGridItem'
import BentoGrid from '@/blocks/BentoGrid/BentoGrid'
import BentoGridItem from '@/blocks/BentoGrid/BentoGridItem'
import BentoGridRow from '@/blocks/BentoGrid/BentoGridRow'
import BlogArticle from '@/blocks/BlogArticle/BlogArticle'
import BlogHomePage from '@/blocks/BlogLandingPage/BlogHomePage'
import CTAButton from '@/blocks/CTAButton/CTAButton'
import CTACard from '@/blocks/CTACard/CTACard'
import CaptionedImage from '@/blocks/CaptionedImage/CaptionedImage'
import CaptionedImageReference from '@/blocks/CaptionedImageReference/CaptionedImageReference'
import CaptionedImagesList from '@/blocks/CaptionedImagesList/CaptionedImagesList'
import CaptionedImagesListItem from '@/blocks/CaptionedImagesList/CaptionedImagesListItem'
import CarConfigurator from '@/blocks/CarConfigurator/CarConfigurator'
import CardsGrid from '@/blocks/CardsGrid/CardsGrid'
import CardsGridItem from '@/blocks/CardsGrid/CardsGridItem'
import CardsGridReference from '@/blocks/CardsGridReference/CardsGridReference'
import ContactCard from '@/blocks/ContactCard/ContactCard'
import CustomDealForm from '@/blocks/CustomDealForm/CustomDealForm'
import FAQ from '@/blocks/FAQ/FAQ'
import FAQItem from '@/blocks/FAQ/FAQItem'
import FAQReference from '@/blocks/FAQ/FAQReference'
import Facts from '@/blocks/Facts/Facts'
import FactsItem from '@/blocks/Facts/Item'
import Hero from '@/blocks/Hero/Hero'
import Item from '@/blocks/Item/Item'
import NewProductSelector from '@/blocks/NewProductSelector/NewProductSelector'
import Page from '@/blocks/Page/Page'
import PressRelease from '@/blocks/PressRelease/PressRelease'
import PressReleases from '@/blocks/PressReleases/PressReleases'
import Product from '@/blocks/Product/Product'
import ProductSelector from '@/blocks/ProductSelector/ProductSelector'
import Reviews from '@/blocks/Reviews/Reviews'
import ReviewsItem from '@/blocks/Reviews/ReviewsItem'
import ReviewsReference from '@/blocks/ReviewsReference/ReviewsReference'
import Richtext from '@/blocks/Richtext/Richtext'
import Section from '@/blocks/Section/Section'
import SectionV2 from '@/blocks/SectionV2/SectionV2'
import ShopsMapReference from '@/blocks/ShopMapReference/ShopMapReference'
import ShopsMap from '@/blocks/ShopsMap/ShopsMap'
import ShopsMapItem from '@/blocks/ShopsMap/ShopsMapItem'
import Steps from '@/blocks/Steps/Steps'
import StepsItem from '@/blocks/Steps/StepsItem'
import StepsReference from '@/blocks/StepsReference/StepsReference'
import Table from '@/blocks/Table/Table'
import TextWithDesktopImage from '@/blocks/TextWithDesktopImage/TextWithDesktopImage'
import TextWithDesktopImagesReference from '@/blocks/TextWithDesktopImagesReference/TextWithDesktopImagesReference'
import config from '@/config'
import { isPreview } from '@/helpers/setCacheControl'
import MenuItemBlok from './components/Layout/MenuItem'
import MenuItemGroup from './components/Layout/MenuItemGroup'

const components = {
  page: Page,
  'shops-map': ShopsMap,
  'shops-map-item': ShopsMapItem,
  'shops-map-reference': ShopsMapReference,
  faq: FAQ,
  'menu-item': MenuItemBlok,
  'menu-item-group': MenuItemGroup,
  ['cta-button']: CTAButton,
  ['faq-item']: FAQItem,
  ['bento-grid']: BentoGrid,
  ['bento-grid-row']: BentoGridRow,
  ['bento-grid-item']: BentoGridItem,
  ['cards-grid']: CardsGrid,
  ['cards-grid-item']: CardsGridItem,
  'advantages-grid': AdvantagesGrid,
  'advantages-grid-item': AdvantagesGridItem,
  'reviews-reference': ReviewsReference,
  reviews: Reviews,
  'reviews-item': ReviewsItem,
  hero: Hero,
  'car-configurator': CarConfigurator,
  'cta-card': CTACard,
  'captioned-image': CaptionedImage,
  'captioned-image-reference': CaptionedImageReference,
  'captioned-images-list': CaptionedImagesList,
  'captioned-images-list-item': CaptionedImagesListItem,
  section: Section,
  sectionv2: SectionV2,
  'steps-reference': StepsReference,
  steps: Steps,
  'steps-item': StepsItem,
  'rich-text': Richtext,
  'faq-reference': FAQReference,
  'text-with-desktop-image': TextWithDesktopImage,
  'custom-deal-form': CustomDealForm,
  'text-with-desktop-images-reference': TextWithDesktopImagesReference,
  'cards-grid-reference': CardsGridReference,
  table: Table,
  'press-release': PressRelease,
  'press-releases': PressReleases,
  'product-selector': ProductSelector,
  'new-product-selector': NewProductSelector,
  'contact-card': ContactCard,
  'blog-article': BlogArticle,
  'about-page': AboutPage,
  'blog-landing-page': BlogHomePage,
  item: Item,
  'new-product': Product,
  facts: Facts,
  'facts-item': FactsItem,
}

export function initStoryblok() {
  storyblokInit({
    accessToken: config.storyblokPreviewToken,
    use: [apiPlugin],
    components,
  })
}

export const commonRelations = [
  'faq-reference.faqs',
  'captioned-image-reference.item',
  'cards-grid-reference.cards_grid',
  'shops-map-reference.shop_map',
  'reviews-reference.reviews',
  'steps-reference.steps',
  'text-with-desktop-images-reference.items',
  'blog-landing-page.new_articles',
  'blog-landing-page.matching_articles',
  'blog-landing-page.popular_articles',
  'blog-landing-page.popular_posts',
  'press-releases.press_releases',
]

type GetStoryWithRelationsArgs = {
  slug: string
  params: (
    | Omit<
        ISbStoriesParams,
        'version' | 'resolve_links' | 'language' | 'fallback_lang'
      >
    | undefined
  ) & {
    resolve_relations: string[]
  }
  storyblokApi: Storyblok
  context: GetServerSidePropsContextWithLocale
}

/**
 * Proxies the storyblok assets via our own cdn for performance reasons
 */
export function rewriteStoryblokAssetUrls(stories: ISbStoryData[]) {
  const storyblokAssetsPrefixUrl = 'https://a.storyblok.com/f/'

  if (!config.storyblokAssetsProxyUrl) return

  for (const story of stories) {
    traverse(story, (node) => {
      if (
        typeof node.filename === 'string' &&
        node.filename.indexOf(storyblokAssetsPrefixUrl) === 0
      ) {
        node.filename =
          config.storyblokAssetsProxyUrl +
          node.filename.split('/').splice(5).join('/')
      }
    })
  }
}

// This function mainly exists because `resolve_relations` combined with `resolve_links` is not working
// as expected. The expected behaviour is that the relations plus their respective links are resolved.
// Storyblok support has confirmed that this is indeed a known limitation. As soon as this is improved, this part of
// the function can be removed. For now, it fixes the issue by doing the following:
// 1. Fetches the story with the `resolve_links` parameters.
// 2. Resolved the relations in the story and fetches the relations by hand, each with the `resolve_links` parameters.
// 3. Merges the resolved relations with the story data.
// See https://www.storyblok.com/tp/using-relationship-resolving-to-include-other-content-entries#reducing-the-size-of-the-response.
export async function getWithRelationsAndLinks({
  slug,
  storyblokApi,
  params,
  context,
}: GetStoryWithRelationsArgs): Promise<ISbResult> {
  const sharedStoryblokOptions = {
    version: isPreview(context) ? ('draft' as const) : ('published' as const),
    language: context.locale,
    fallback_lang: context.defaultLocale,
    resolve_links: 'url' as const,
  }

  const storyData = await storyblokApi.get(slug, {
    ...params,
    ...sharedStoryblokOptions,
    resolve_relations: undefined,
  })

  const stories: ISbStoryData[] = storyData.data.story
    ? [storyData.data.story]
    : storyData.data.stories ?? []

  if (!Array.isArray(params?.resolve_relations)) {
    rewriteStoryblokAssetUrls(stories)
    return storyData
  }

  if (!stories.length) {
    return storyData
  }

  const relationIds: Array<string> = []

  for (const story of stories) {
    traverse(story, (node) => {
      for (const relation of params.resolve_relations || []) {
        const [componentName, fieldName] = relation.split('.')
        if (nodeContainsRelation(node, componentName, fieldName)) {
          const idOrArray = node[fieldName]
          if (typeof idOrArray === 'string') {
            relationIds.push(idOrArray)
          } else if (Array.isArray(idOrArray)) {
            relationIds.push(...idOrArray)
          }
        }
      }
    })
  }

  const storiesData = await storyblokApi.getStories({
    by_uuids: relationIds.join(','),
    ...sharedStoryblokOptions,
  })

  for (const story of stories) {
    traverse(story, (node) => {
      for (const relation of params.resolve_relations || []) {
        const [componentName, fieldName] = relation.split('.')
        if (nodeContainsRelation(node, componentName, fieldName)) {
          const idOrArray = node[fieldName]
          if (typeof idOrArray === 'string') {
            const story = storiesData.data.stories.find(
              (story) => story.uuid === idOrArray,
            )
            if (story) {
              node[fieldName] = story
            }
          } else if (Array.isArray(idOrArray)) {
            node[fieldName] = []
            for (const id of idOrArray) {
              const story = storiesData.data.stories.find(
                (story) => story.uuid === id,
              )
              if (story) {
                node[fieldName].push(story)
              }
            }
          }
        }
      }
    })
  }

  rewriteStoryblokAssetUrls(stories)

  return storyData
}

function traverse(
  node: Record<string, any> | Array<Record<string, any>>,
  callback?: (node: Record<string, any>) => void,
) {
  if (typeof node === 'object' && node !== null) {
    if (callback && !Array.isArray(node)) {
      callback(node)
    }
    if (Array.isArray(node)) {
      for (const item of node) {
        traverse(item, callback)
      }
    } else {
      for (const value of Object.values(node)) {
        traverse(value, callback)
      }
    }
  }
}

function nodeContainsRelation<T extends Record<string, any>>(
  node: T,
  componentName: string,
  fieldName: string,
): node is T & { [key in typeof fieldName]: string } {
  if (
    'component' in node &&
    node.component === componentName &&
    fieldName in node
  ) {
    const idOrArray = node[fieldName]
    if (
      (typeof idOrArray === 'string' && idOrArray.length > 0) ||
      (Array.isArray(idOrArray) && idOrArray.length > 0)
    ) {
      return true
    }
  }
  return false
}
